import { v4 as uuidv4 } from 'uuid'

const VISITOR_ID_KEY = 'brek-visitor-id'

const isClient =
  typeof window !== 'undefined' && typeof localStorage !== 'undefined'

export const getOrCreateVisitorId = (): string | null => {
  if (!isClient) {
    console.log('getOrCreateVisitorId is not client')
    return null
  }

  let visitorId = localStorage.getItem(VISITOR_ID_KEY)
  if (!visitorId) {
    visitorId = uuidv4()
    localStorage.setItem(VISITOR_ID_KEY, visitorId)
  }
  return visitorId
}
