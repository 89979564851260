import { PostDetail } from '@/types/api/post'
import { pickImageSize } from '@/utils/image'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Avatar from '../Avatar'
import { useInView } from 'react-intersection-observer'
import { RiHeartLine } from 'react-icons/ri'

const MAX_THUMB_HEIGHT = 300

type Props = {
  index?: number
  feed: PostDetail
  markAsSeen?: () => void
  onPostClick?: () => void
}

const PostListItem = ({ index, feed, markAsSeen, onPostClick }: Props) => {
  const thumbnail = pickImageSize(feed.thumbnail)
  const [imageHeight, setImageHeight] = useState(MAX_THUMB_HEIGHT)
  const containerRef = useRef<HTMLDivElement>(null)

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      markAsSeen?.()
    }
  }, [inView, markAsSeen])

  useEffect(() => {
    const updateImageHeight = () => {
      if (containerRef.current && thumbnail.width && thumbnail.height) {
        const containerWidth = containerRef.current.offsetWidth
        const aspectRatio = thumbnail.width / thumbnail.height
        const calculatedHeight = Math.round(containerWidth / aspectRatio)
        setImageHeight(Math.min(calculatedHeight, MAX_THUMB_HEIGHT))
      }
    }

    updateImageHeight()
    window.addEventListener('resize', updateImageHeight)

    return () => {
      window.removeEventListener('resize', updateImageHeight)
    }
  }, [thumbnail])

  // Combine the inView ref with the container ref
  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      containerRef.current = node
      inViewRef(node)
    },
    [inViewRef],
  )

  return (
    <div ref={setRefs} className="w-full">
      <Link
        href={`/post/${feed.postId}`}
        key={feed.postId}
        className="block w-full"
        onClick={onPostClick}
      >
        <div className="relative w-full" style={{ height: `${imageHeight}px` }}>
          <Image
            src={thumbnail.url}
            alt={feed.title}
            fill
            className="rounded-lg bg-gray-300 object-cover"
          />
        </div>
        <div className="px-2">
          <h2 className="line-clamp-2 py-1 text-base font-medium">
            {feed.title}
          </h2>
          <div className="flex items-center">
            <Avatar url={pickImageSize(feed.author.avatar)?.url} size={18} />
            <span className="ml-1 flex-grow truncate text-sm text-gray-600">
              {feed.author.userDisplayName}
            </span>
            <div className="flex items-center">
              <RiHeartLine className="h-4 w-4 text-gray-600" />
              <span className="ml-1 text-sm text-gray-600">
                {feed.numberOfLikes}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostListItem
