const LOCAL_ROOT = 'http://127.0.0.1:8002'
const LOCAL_IP_ROOT = 'http://192.168.50.145:8002'
const DEV_ROOT = 'https://social-api-dev.vhrgateway.com'

const PROD_ROOT = 'https://social-api.vhrgateway.com'

export const LOCAL_TEST_BASE_URL = DEV_ROOT

const getIsProdEnv = () => {
  console.log(
    'process.env.APP_ENV',
    process.env.APP_ENV,
    process.env.NEXT_PUBLIC_IS_VERCEL_PREVIEW,
    process.env.NODE_ENV,
  )

  if (process.env.APP_ENV === 'staging') {
    return false
  }
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_IS_VERCEL_PREVIEW !== '1'
  ) {
    return true
  }
  return false
}

export function getUrlRoot() {
  let rootUrl = getIsProdEnv() ? PROD_ROOT : DEV_ROOT

  if (process.env.NEXT_PUBLIC_LOCAL) {
    rootUrl = LOCAL_TEST_BASE_URL
  }

  return rootUrl
}

export function getHttpUrlRoot() {
  return getUrlRoot() + '/api'
}
