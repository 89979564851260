import { AxiosHeaders, AxiosInstance, isAxiosError } from 'axios'
import { CommonError } from './axios'
import { getOrCreateVisitorId } from '@/lib/identity'

export const addCommonRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async function (config) {
      if (!config.headers) {
        config.headers = new AxiosHeaders()
      }

      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json'
      }

      config.headers['brek-platform'] = 'web'
      const visitorId = getOrCreateVisitorId()
      if (visitorId) {
        config.headers['brek-visitor-id'] = visitorId
      }

      // console.debug(
      //     ">>>>> request config",
      //     instance.defaults.baseURL,
      //     config.method,
      //     config.url,
      //     "headers",
      //     config.headers,
      //     "data",
      //     config.data,
      // );

      return config
    },
    function (error) {
      console.debug('request error', JSON.stringify(error))
      // Do something with response error
      if (isAxiosError(error) && error.config) {
        const config = error.config
      }
      // Do something with request error
      return Promise.reject(error)
    },
  )
}

/**
 * convert error to app used common error
 * @param error
 * @returns
 */
const convertError = (error: any) => {
  let statusCode = error.status || error.statusCode || 500
  let message = error.message
  let rawMessage = error.message
  let errorCode
  // Do something with response error
  if (isAxiosError(error) && error.response?.data) {
    const errorData = error.response.data
    if (errorData.statusCode) {
      statusCode = errorData.statusCode
    }
    if (Array.isArray(errorData.message)) {
      message = errorData.message.length > 0 ? errorData.message[0] : ''
      rawMessage = errorData.message
    } else if (errorData.message) {
      message = errorData.message
      rawMessage = errorData.message
    }
    if (errorData.code) {
      errorCode = errorData.code
    }
  }

  const commonError: CommonError = {
    message,
    rawMessage,
    errorCode,
    statusCode,
  }

  return commonError
}

export const addCommonResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      // logger.debug("response", response);
      if (!response.data) {
        return Promise.reject(new Error('No data'))
      }
      // console.debug(
      //     "<<<<< response.data",
      //     response.config.url,
      //     response.data,
      // );
      if (!response.data.message || response.data.message !== 'success') {
        return Promise.reject(new Error(response.data.message || 'No message'))
      }
      return response
    },
    function (error) {
      const commonError = convertError(error)
      console.error(
        '<<<<< Response error',
        error.response?.config?.url,
        commonError,
      )
      return Promise.reject(commonError)
    },
  )
}
