import Image from 'next/image'
import React from 'react'
import { RiUserLine } from 'react-icons/ri'

type Props = {
  url?: string
  size?: number
}

const Avatar = ({ url, size = 24 }: Props) => {
  return (
    <>
      {url ? (
        <Image
          src={url}
          width={size}
          height={size}
          alt={`avatar`}
          className="shrink-0 rounded-full object-cover"
          style={{ width: size, height: size }}
        />
      ) : (
        <div className="rounded-full border p-0.5">
          <RiUserLine size={size - 3 * 2} />
        </div>
      )}
    </>
  )
}

export default Avatar
