import React from 'react'
import { list } from 'radash'
import dynamic from 'next/dynamic'
import { PostDetail } from '@/types/api/post'
import PostListItem from './PostListItem'
import PostListItemSkeleton from './PostListItemSkeleton'

const ResponsiveMasonry = dynamic(
  () => import('react-responsive-masonry').then((mod) => mod.ResponsiveMasonry),
  { ssr: false },
)
const Masonry = dynamic(
  () => import('react-responsive-masonry').then((mod) => mod.default),
  { ssr: false },
)

type Props = {
  isInitialLoading?: boolean
  posts: PostDetail[]
  markPostAsSeen?: (postId: string) => void
  onPostClick?: (postId: string) => void
}

const PostList = ({
  isInitialLoading,
  posts,
  markPostAsSeen,
  onPostClick,
}: Props) => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3 }}>
      {isInitialLoading ? (
        <Masonry gutter={'0.75rem'}>
          {list(5).map((index) => (
            <PostListItemSkeleton key={index} />
          ))}
        </Masonry>
      ) : (
        <Masonry gutter={'0.75rem'}>
          {posts?.map((item, index) => (
            <PostListItem
              key={index}
              feed={item}
              index={index}
              markAsSeen={() => markPostAsSeen?.(item.postId)}
              onPostClick={() => onPostClick?.(item.postId)}
            />
          ))}
        </Masonry>
      )}
    </ResponsiveMasonry>
  )
}

export default PostList
