import React from 'react'
import urlJoin from 'url-join'

type Props = {
  path: string
  title?: string
}

const DownloadAppButton = ({ path, title = 'More in Brek App' }: Props) => {
  const showAppDownloadAlert = (path: string) => {
    const appScheme = 'brekapp://'
    const appStoreLink = 'https://apps.apple.com/app/id6504866617'
    const playStoreLink =
      'https://play.google.com/store/apps/details?id=com.pageonelab.brekapp'

    const fullUrl = urlJoin(appScheme, path)
    console.debug('fullUrl', fullUrl.toString())
    window.location.href = fullUrl.toString()

    setTimeout(() => {
      if (document.hidden) return

      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.href = appStoreLink
      } else {
        window.location.href = playStoreLink
      }
    }, 2000)
  }

  return (
    <div className="fixed bottom-20 left-0 right-0 flex justify-center lg:hidden">
      <button
        onClick={() => {
          showAppDownloadAlert(path)
        }}
        className="rounded-full bg-primary px-6 py-3 text-white shadow-lg transition-transform hover:scale-105 active:scale-95"
      >
        {title}
      </button>
    </div>
  )
}

export default DownloadAppButton
