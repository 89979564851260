import { useEffect, useCallback, FC, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import { RiLoader4Line } from 'react-icons/ri'
import { classMerge } from '@/lib/utils'

interface InfiniteScrollProps {
  className?: string
  loadMore?: () => Promise<void>
  hasMore: boolean
  isInitialLoading?: boolean
  isLoadingMore?: boolean
  error: string | null
  onRetry: () => void
  children: ReactNode
  loader?: ReactNode
  endMessage?: ReactNode
  loadMoreButtonTitle?: string
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  className = '',
  loadMore,
  isInitialLoading = true,
  hasMore,
  isLoadingMore,
  error,
  onRetry,
  children,
  loader = <RiLoader4Line className="size-6 animate-spin" />,
  endMessage = <p>No more items to load</p>,
  loadMoreButtonTitle = 'Load More',
}) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.1,
    rootMargin: '0px 0px 200px 0px',
  })

  const handleLoadMore = useCallback(async () => {
    if (!hasMore || isLoadingMore || !loadMore) return
    await loadMore()
  }, [loadMore, hasMore, isLoadingMore])

  useEffect(() => {
    if (inView && handleLoadMore) {
      handleLoadMore()
    }
  }, [inView, handleLoadMore])

  if (error) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center px-3 text-center">
        <h2 className="mb-4 text-xl font-semibold">
          Oops! Something went wrong
        </h2>
        <p className="mb-4">{error}</p>
        <button
          className="h-10 rounded-md bg-black px-4 py-2 text-white"
          onClick={onRetry}
        >
          Try Again
        </button>
      </div>
    )
  }

  return (
    <div className={classMerge('h-full overflow-y-auto', className)}>
      {children}
      {!isInitialLoading && (
        <div className="flex h-16 items-center justify-center">
          {isLoadingMore && loader}
          {!isLoadingMore && hasMore && (
            <div
              ref={inViewRef}
              onClick={handleLoadMore}
              className="cursor-pointer rounded px-4 py-2 text-gray-500"
            >
              {loadMoreButtonTitle}
            </div>
          )}
          {!isLoadingMore && !hasMore && endMessage}
        </div>
      )}
    </div>
  )
}

export default InfiniteScroll
